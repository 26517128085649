.Stl-cron {
  .row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .form-check {
    position: relative;
    display: block;
    padding-left: 1.25rem;
  }

  .form-check-input {
    position: absolute;
    margin-top: 0.3rem;
    margin-left: -1.25rem;
  }

  .form-inline {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -ms-flex-align: center;
    align-items: center;
  }
  .pl-3 {
    padding-left: 1rem !important;
  }
  .pt-1 {
    padding-top: 0.25rem !important;
  }

  .col-1 {
    position: relative;
    width: 100%;
    // padding-right: 15px;
    padding-left: 15px;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    // max-width: 8.333333%;
  }
  /*   .col-2 {
    position: relative;
    width: 100%;
    // padding-right: 15px;
    padding-left: 15px;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    // max-width: 8.333333%;
  } */

  .form-group {
    margin-bottom: 1rem;
  }

  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d;
  }

  .ml-1,
  .mx-1 {
    margin-left: 0.25rem !important;
  }
  .mr-1,
  .mx-1 {
    margin-right: 0.25rem !important;
  }

  .pl-3,
  .px-3 {
    padding-left: 1rem !important;
  }
  .pt-1,
  .py-1 {
    padding-top: 0.25rem !important;
  }

  .col-2 {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    flex: 0 0 11.333333%;
  }
  .col-3 {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
  }

  .form-control-sm {
    height: calc(1.8125rem + 2px);
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
  }

  @media (min-width: 576px) {
    .form-inline .form-check {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-pack: center;
      justify-content: center;
      width: auto;
      padding-left: 0;
    }
    .form-inline .form-check-input {
      position: relative;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0;
    }
    .form-inline label {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-pack: center;
      justify-content: center;
      margin-bottom: 0;
    }
  }

  //
  ul {
    margin: 0;
    padding: 0;

    li.nav-item.c-tab-item {
      display: inline-block;
      margin-left: 2px;
    }

    button.nav-link {
      background-color: #ffffff;
      border-radius: 2px 2px 0 0;
      border-top: 1px solid rgba(0, 0, 0, 0.06);
      border-right: 1px solid rgba(0, 0, 0, 0.06);
      border-left: 1px solid rgba(0, 0, 0, 0.06);
      border-bottom: none;

      /*  color: #1890ff; */
      cursor: pointer;
      padding: 8px 16px;

      //transition: all 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
      outline: none;
      &:first-child {
        margin-left: 0;
      }
      &.active {
        color: #1890ff;
        border-bottom: solid 2px #1890ff;
      }
    }
  }
  .c-tab-content {
    padding: 1em;
  }
}
