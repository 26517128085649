.NotFoundPage {
  text-align: center;
  h1 {
    font-size: 40px;
    margin: 0;
  }
  p {
    color: rgba(0, 0, 0, 0.85);
    font-size: 180px;
    padding: 0;
    margin: 0;
  }
  img {
    width: 300px;
  }
}
