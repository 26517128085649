.StlHeader {
  background-color: #e50000;
  color: #fff;
  height: auto;

  &:before {
    content: "";
    position: absolute;
    width: 200px;
    height: 240px;
    top: -140px;
    right: 56px;
    background-color: rgba(255, 255, 255, 0.2);
    transform: rotate(45deg);
    border-radius: 0 0 135px 135px;
  }

  div.topSubHeader {
    height: 40px;
    display: flex;
    justify-content: space-between;
    line-height: 40px;
  }

  div.bottomSubHeader {
    height: 57px;
    display: flex;
    justify-content: space-between;
    line-height: 57px;
    div.logo {
      width: 10%;
    }
    div.menuBox {
      width: 95%;
    }
    div.userInfoBox {
      width: 5%;
    }
  }

  /*div {
    float: left;
  }

  div.topSubHeader {
    float: none;
    overflow: auto;
    .AppName {
      float: left;
      margin-left: 30px;
      color: #f5f5f5;
    }
    .logoutBox {
      float: right;
      text-align: right;
      display: block;
      color: #f5f5f5;
      padding-right: 40px;
    }
  }

  div.logo {
    color: #f5f5f5;
    margin-left: 30px;
  }
  div.menuBox {
    margin-left: 50px;
  }
  div.userInfoBox {
    float: right;
    line-height: 46px;
    padding-right: 20px;
  } */
}
