.StlMenu {
  background-color: transparent;
  border-bottom: none;
  li {
    color: #fff !important;
    &:hover {
      color: #fff !important;
      &::after {
        border-bottom: 2px solid #fff !important;
      }
    }

    &.ant-menu-item-selected.StlMenu__items {
      color: #fff !important;

      &::after {
        border-bottom: 2px solid #fff !important;
      }
    }
  }
  &.ant-menu-horizontal {
    background-color: green !important;
  }
}

:global .ant-menu-item-selected {
  color: #fff !important;
  &::after {
    border-bottom: 2px solid #fff !important;
  }
}
