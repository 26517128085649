.StlTable {
  table {
    color: #4a505c !important;
  }

  tr > th {
    background-color: rgba(205, 84, 71, 0.1) !important;
    color: rgba(205, 84, 71, 0.8) !important;
    /*     padding: 0 1.14286rem !important;
    height: 40px !important; */
  }
}
